import CONSTANTS from "config/constants";

const { TITLE } = CONSTANTS;

const en = {
  title: "Simple Commerce",
  home: {
    metaTitle: TITLE,
    metaDescription:
      "Enjoy the best movies and snacks. Only in Multicines you will find what you are looking for."
  },
  highTraffic: {
    title1: "Hold on while we secure your seat",
    content1:
      "You are about to make your purchase for the movie you have been eagerly waiting for. Thank you for your patience while we process your request.",
    title2: "Let the show go on",
    content2:
      "You can now proceed with your purchase and get ready to enjoy your movie.",
    buttonContent: "Continue Purchase"
  },
  footer: {
    titles: {
      account: "Account",
      data: "Data",
      contact: "Contact"
    },
    copyright: "All rights reserved.",
    develop: "Develop by",
    navigation: {
      birthday: "Birthday",
      events: "Special events",
      coupons: "Corporate coupons",
      ads: "Advertising",
      services: "Corporate services",
      snacks: "Snacks",
      multidrive: "Multidrive In",
      vip: "VIP lounges",
      service: "Services",
      billing: "Billing",
      terms: "Privacy Policy Organic Law on Personal Data Protection",
      faq: "FAQ",
      us: "Us",
      aboutUs: "About us",
      workUs: "Work with us",
      contact: "Contact us",
      legals: "Legals",
      whatsapp: "Whatsapp",
      firstIssue: "First issue of liabilities Multicines S.A.",
      extendFirst:
        "Extension of the first issue of liabilities Multicines S.A.",
      secondIssue: "Second issue of liabilities Multicines S.A."
    }
  },
  productsSearch: {
    metaTitle: "Discover"
  },
  loyaltyWarningScreen: {
    title: "You are already a MultiClub member",
    description:
      "You are already a MultiClub member, if you want to change your account, contact support",
    logout: "Logout and enter with another account",
    needHelp: "Need help?",
    support: "Contact with Support",
    continueWithoutMulticlub: "Continue without MultiClub loyalty program"
  },
  coupons: {
    metaTitle: "Coupons",
    detailTitle: "Coupons",
    available: "Available",
    haveCoupon: "Do you have a coupon?",
    enterCoupon: "Enter your coupon",
    empty: "Add a coupon to your wallet!",
    emptyDescription:
      "You haven't added coupons to your wallet yet, try redeeming a code to get coupons"
  },
  profile: {
    metaTitle: "User",
    signIn: "Sign in",
    signUp: "Sign up",
    edit: {
      title: "Edit your profile",
      change: "Change password",
      successful: "Data updated successfully",
      deleteAccount: "Delete account",
      save: "Save changes"
    },
    cards: {
      title: "Saved cards",
      addButton: "Add",
      emptyTitle: "Save your cards",
      emptyDescription: "Add a card to make your purchases even faster",
      errorConfirmation: "Don't forget to accept the terms and conditions"
    },
    billing: {
      title: "Billing information",
      empty: "Add your billing information!",
      emptyButton: "New billing information",
      saved: "Billing information saved",
      maxBilling: {
        possible: "It is only possible to have",
        active: "active billing data",
        limitation: "Billing data limit exceeded"
      },
      invalidPhone: "The phone number entered is not valid",
      updateErrorData: "There was a problem updating the billing information",
      saveErrorData: "There was a problem saving the billing information"
    },
    wishlist: {
      tickets: "Your tickets",
      all: "See all",
      title: "wishlist"
    },
    ticket: {
      title: "Tickets",
      tickets: "Your tickets",
      all: "See all"
    },
    notification: {
      title: "Notifications",
      activateNotifications: "I agree to receive emails",
      empty: "Notifications of your reservations will be displayed here",
      emptyButton: "Explore movies"
    },
    order: {
      metaTitle: "Purchase history",
      empty: "Make your first purchase!"
    },
    ticketsCarousel: {
      startIn: "Starts in",
      details: "View details"
    },
    orderCard: {
      help: "Help",
      details: "See details",
      assisted: "Assisted",
      toBeAssisted: "For attending",
      canceled: "Canceled",
      rejected: "Rejected",
      approved: "Approved",
      pending: "Pending",
      previous: "Processed purchases",
      lastPurchases: "Purchases in progress"
    },
    orderDetails: {
      instructions: "Get your order showing the QR code",
      seats: "Seats"
    },
    orderHistory: {
      title: "Order history",
      empty: "You haven't bought tickets yet",
      emptyMessage:
        "What are you waiting for? Explore our billboard and make your first purchase"
    },
    terms: "Legal terms",
    support: "Support"
  },
  filters: {
    title: "Filter by",
    filter: "Filters",
    all: "All",
    premiere: "Premiere",
    presale: "Presale",
    preview: "Coming soon",
    view: "View",
    languages: "Languages",
    type: "Theater type",
    format: "Movie format",
    spa: "Spanish",
    sub: "Subtitled",
    billboard: "Billboard",
    now: "Now",
    nextFunction: "The next function starts at",
    reset: "Reset"
  },
  movie: {
    premiere: "Premiere",
    presale: "Presale",
    exclusivePresale: "Exclusive Pre-sale",
    age: "years",
    preview: "Coming soon",
    allPublic: "All public",
    withSupervision: "under supervision",
    emptyMessage: "No film screenings available for this cinema on this date",
    emptyButton: "Search next available date",
    availableSeats: "Available seats",
    selectCinema: "Select cinema",
    screen: "Screen",
    saveReservation: "Do you want to save your reservation process?",
    exitMessage:
      "You can save your process and continue later, if you leave you will lose your reservation",
    continueReservation: "Continue with the reservation",
    saveAndContinueLater: "Save and continue later",
    exit: "Exit process",
    pointsRestriction: "Points restriction",
    today: "Today",
    available: "Available",
    unavailable: "Unavailable",
    depleted: "Out of stock",
    emptyBillboard: "We are renewing the billboard",
    emptyBillboardMessage:
      "Try to change cinema to see other billboards available",
    cancelReservationError: "Cancellation failed, try again",
    emptyStores: "No cinemas available",
    emptyStoresMessage:
      "There are currently no cinemas available, please try again later.",
    theater: "Theater",
    screenings: "Screenings",
    trailers: "Trailers",
    information: "Information",
    nextAvailableDate: "Find next available date",
    synopsis: "Synopsis",
    noSynopsis: "No synopsis available",
    actors: "Cast",
    seeAll: "See all",
    seeLess: "See less",
    selectSchedule: "Select your schedule",
    allLanguages: "All languages",
    allRooms: "All rooms",
    allFormats: "All formats",
    ticketDetails: "Details of your tickets",
    gallery: "Gallery",
    noTrailers: "No trailers available",
    previewTitle: "Next premiere!",
    previewDescription: "Very soon this movie will be in theaters",
    emptyMovieDescription:
      "No film screenings available for this movie on this date"
  },
  navigation: {
    shopping: "Shopping",
    discover: "Discover",
    loyalty: "Loyalty",
    coupons: "Coupons",
    profile: "User",
    snacks: "Snacks",
    movies: "Movies",
    spotlight: "Spotlight",
    multiclub: "Multiclub",
    login: "Login",
    logout: "Logout",
    Navbar: {
      selectCinema: "Select a cinema"
    }
  },
  catalogues: {
    delivery: "Delivery",
    pickup: "Pick-Up",
    other: "Other"
  },
  tickets: {
    minError: "Choose at least one ticket",
    empty: "We are counting tickets",
    title: "Your tickets",
    selectTickets: "Select your tickets",
    notEnoughPoints: "Not enough points",
    cantAddMore: "You can't add more",
    info: "Your ID will be requested to enter the function"
  },
  downloadBanner: {
    title: "Take the cinema experience now in your hands",
    downloadApp: "Download App",
    download: "Download"
  },
  common: {
    person: "person",
    applyFor: "apply for: ",
    invalidUser: "Invalid user",
    invalidSession: "Invalid session",
    understood: "Understood",
    vistaError: "Vista: unavailable service",
    errorSelectedCityStore: "Select a cinema",
    dateAndHour: "Date and time",
    preferences: "Preferences",
    workflows: "Purchase method",
    apply: "Apply",
    buyingIn: "Buying in",
    catalogue: "Catalogue",
    continue: "Continue",
    retry: "Retry",
    send: "Send",
    confirm: "Confirm",
    cancel: "Cancel",
    edit: "Edit",
    change: "Cambiar",
    soon: "Stay tuned, this feature is coming soon!",
    goToPay: "Go to pay",
    pay: "Pay",
    emailAddress: "Email address",
    warning: "Are you sure that you want to leave?",
    errorTitle: "Something went wrong",
    errorMessage:
      "If the problem persists, contact support and we will help you solve it.",
    add: "Add",
    update: "Update",
    accept: "Accept",
    save: "Save",
    delete: "Delete",
    keep: "Don't delete",
    changeSession: "Change session",
    changeSchedule: "Change schedule",
    changeCinema: "Change cinema",
    emptyMessage: "Try to change the schedule to see other available tickets",
    emptyAction:
      "Please try changing cinema to see other menu options or try again later",
    goBack: "Go back",
    remainingTime: "Remaining time",
    tryAgain: "Try again",
    buyTickets: "Buy your tickets",
    trailer: "Watch trailer",
    numberOrder: "Nro de orden.",
    store: "Store",
    billing: "Billing",
    email: "Email",
    paymentMethod: "Payment method"
  },
  forms: {
    name: "Name",
    lastName: "Last name",
    nameAndLastName: "Name and last name",
    documentType: "Document type",
    document: "Document",
    documentNumber: "Document number",
    email: "E-mail",
    emailAddress: "Email address",
    emailPlaceholder: "example@email.com",
    address: "Address",
    addAddress: "Add new address",
    phone: "Phone number",
    password: "Password",
    cardNumber: "Card number",
    cardHolder: "Card holder",
    expirationDate: "Expiration date",
    cvv: "CVV",
    dateOfBirth: "Date of birth",
    dateOfBirthPlaceholder: "DD / MM / YYYY",
    passport: "Passport",
    ci: "CI",
    businessName: "Business name",
    invalidDateOfBirth: "Invalid date of birth"
  },
  errors: {
    qr: "An error happened while trying to generate the QR code",
    invalidUser: "Invalid user",
    storeNotFound: "Complejo no encontrado",
    generic: "Something went wrong",
    invalidDocument: "Invalid document number",
    validationError: "Validation error",
    purchaseFail: "The process has been abandoned",
    google: {
      canceled: "Authorization attempt canceled",
      token: "Something went wrong getting the authorization token",
      playServicesNotAvailable: "Play services are not available or outdated",
      inProgress:
        "You're trying to invoke another operation while previous one has not yet finished",
      signInRequired: "No user has signed in yet"
    },
    facebook: {
      cancel: "Authorization attempt canceled",
      token: "Something went wrong getting the authorization token"
    },
    apple: {
      canceled: "Authorization attempt canceled",
      token: "Apple Sign-In failed - no identify token returned",
      failed: "The authorization attempt failed",
      invalid: "The authorization request received an invalid response",
      notHandled: "The authorization request wasn't handled",
      unknown: "The authorization attempt failed for an unknown reason"
    },
    firebase: {
      auth: {
        wrongPassword: "Invalid data",
        userNotFound:
          "There is no existing user record corresponding to the provided identifier",
        tooManyRequests:
          "Too many requests. The access to this account has been temporally disabled",
        emailAlreadyInUse:
          "The provided email is already in use by an existing user",
        networkRequestFailed: "A network error has occurred, try again",
        operationNotAllowed:
          "The provided sign-in provider is disabled for your Firebase project",
        accountExistsWithDifferentCredential:
          "An account already exists with the same email address but different sign-in credentials",
        invalidActionCode: "The used link is invalid"
      }
    },
    shoppingCart: {
      createCart: "Failed to create the shopping cart",
      addToCart: "Failed adding the product, try again"
    },
    tickets: {
      max: "Availability of",
      reached: "reached",
      deeplink: "Error at loading deeplink information, try again later"
    },
    seats: {
      full: "Deselect a seat to choose a new one",
      unavailable: "Not allowed seats for this zone"
    },
    fallback: "Request failed, please try again",
    support:
      "You are already a MultiClub member, write to customer service@multicines.com.ec with your ID and email to update it",
    loyaltyCommonError:
      "Verify that the ID and date of birth you enter are valid."
  },
  checkout: {
    title: "Checkout",
    sso: "You can login with your account",
    personalInfo: "Personal information",
    paymentMethod: "Payment method",
    cash: "Cash",
    card: "Card",
    loyalty: "Loyalty",
    pay: "Pay",
    acceptTerms: "Accept the ",
    terms: "terms and conditions and data privacy policies",
    faq: "Frequently asked questions",
    BillingSection: {
      billingTitle: "Personal information",
      billingInfo: "Billing information",
      addBilling: "Add billing data",
      sameBilling: "Use the same data for billing",
      noSelectedTitle: "Select billing",
      noSelectedDescription: "Please select the billing to continue"
    },
    addCard: "+ Add new card",
    chooseCard: "Choose card",
    changeCard: "Change card",
    selectedCardError:
      "The selected card cannot be used in this purchase, we recommend you change it"
  },
  purchaseStatus: {
    orderCreation: "We're creating the order",
    buildingOrderError: "There was an error creating the order",
    paymentValidation: "We're validating your payment",
    success: "Successful purchase!",
    p2pSuccess: {
      reference: "Your payment with reference number",
      value: "and value",
      approved: "was approved"
    },
    p2pPending: {
      reference: "You have a pending payment with reference number",
      value: "and value"
    },
    p2pRejected: {
      reference: "Your payment with reference number",
      value: "and value",
      rejected: "was rejected"
    },
    pendingPayment:
      "is pending. The user has a pending payment with reference number ",
    pendingValidation:
      "The card will be approved when the charge is validated, which will be returned immediately.",
    errorTitle: "There was an error processing the order",
    errorDescription:
      "The order could not be processed due to an error, please try again",
    approved: "approved",
    rejected: "rejected",
    pending: "pending",
    cardError: "The subscription of the card with reference number",
    errorValidation: "was rejected",
    card: "Card",
    addedCard: "Card added successfully",
    successCard: {
      subscription: "The card subscription with reference number",
      hasBeen: "has been",
      charge: "We have generated a verification charge for",
      refund: "this amount will be refunded in a few minutes"
    }
  },
  auth: {
    signIn: "Sign in",
    signUp: "Sign up",
    AuthNotification: {
      benefits: "",
      signIn: "Sign in with your account and enjoy benefits"
    },
    ChooseAuth: {
      omit: "Dismiss",
      title: "Sign up and enjoy incredible benefits",
      alternativeSignIn: "Or sign in with your email address",
      alternativeSignUp: "Or sign up with your email address",
      email: "Use email address"
    },
    SSOButton: {
      continue: "Continue with"
    },
    MagicLink: {
      titleSignIn: "Sign in with your email address",
      titleSignUp: "Register with your email address",
      signInContinue: "Sign in",
      signUpContinue: "Sign up",
      alternativeSignIn: "Need a new account?",
      alternativeSignUp: "Already have an account?",
      signIn: "Create account",
      signUp: "Sign in to your account"
    },
    CheckEmailVerification: {
      title: "Check your email inbox",
      instructions: "Click the link we sent to the email address",
      instructions2: "to sign in to your account",
      help: "If you haven't received anything, check your SPAM inbox, or you can",
      tryAgain: "Resend the email"
    },
    SecondStep: {
      title: "We want to know a little more about you",
      continue: "Create account"
    },
    SignInConfirmation: {
      title: {
        loading: "Signing in...",
        success: "Sign in successfully",
        error: "Something went wrong trying to sign in"
      }
    }
  },
  global: {
    distanceAlert: {
      title: "You are a bit far from the selected cinema"
    },
    especialInfo:
      "Al comprar boletos de discapacidad o tercera edad se solicitará su cédula de identidad para ingresar a la función.",
    corporativeMessage:
      "Los códigos corporativos con valor $0 ya fueron prepagados"
  },
  seats: {
    empty: "We are cleaning the cinema",
    Seats: {
      incompleteSeatsError: "Choose your seats",
      reservationError: "Reservation failed, try again",
      reservationVistaError: "Vista: ",
      select: "Your seats",
      seatsScroll: "Swipe sideways to view all seating sections.",
      alreadyRedeemed: "The corporative ticket has already been redeemed"
    },
    SeatsSelection: {
      reserved: "Reserved",
      available: "Available",
      selected: "Selected",
      distancing: "Distancing"
    },
    SeatsCategory: {
      tickets: "Ticket"
    },
    detail: "Details of your tickets"
  },
  orderDetails: {
    downloadSuccessTitle: "Download successful",
    downloadSuccessMessage: "Your order details have been downloaded.",
    BillingSummary: {
      billing: "Billing",
      paymentMethod: "Payment method",
      transactionDate: "Transaction date",
      transactionTime: "Transaction time",
      reference: "Reference",
      status: "Status",
      orderCode: "Order code",
      paymentStatus: "Payment status",
      cardPayment: "Card payment",
      pointsPayment: "Redemption of points",
      auth: "Authorization number",
      multipower: "Multipower",
      corporativePayment: "Boleto corporativo"
    },
    OrderDetails: {
      instructions: "Get your order showing the QR code",
      seats: "Seats",
      share: "Share",
      download: "Download",
      info: "You can present a screenshot in case you have connection problems"
    },
    OrderSummary: {
      each: "e/o",
      total: "Total"
    }
  },
  categories: {
    title: "Categories",
    empty: "We are changing our menu",
    emptyProducts: "No products",
    emptyDescription:
      "It seems there are no products here, try browsing other categories",
    emptyButton: "Explore",
    error: "Something went wrong",
    errorDescription:
      "There was an error loading the page, try reloading the page or come back later",
    errorButton: "Explore more products"
  },
  greetings: {
    simple: "enjoy the best of cinema",
    snacks: "Enjoy the best snacks",
    profile: "Hello",
    discover: "Discover the best movies",
    billboard: "See billboard",
    profileAnonymous: "Sign in with your account",
    profileAnonymousDescription:
      "Sign in to create lists, see your purchase history and enjoy all the exclusive benefits we have for you."
  },
  cart: {
    title: "Cart",
    tickets: "Tickets",
    snacks: "Snacks",
    subtotal: "Subtotal",
    total: "Total",
    taxes: "Taxes",
    add: "Add to cart",
    addSnacks: "Add snacks",
    exploreSnacks: "Explore more snacks",
    empty: "Get ready to live the best experience!",
    emptyMessage:
      "Choose your favorite movie, add snacks to your order and enjoy the best of Multicines",
    confirmStore: "Confirm purchase at ",
    errorStore: "Don't forget to confirm the store",
    ticketsDetail: "See order detail",
    refund: "Once the purchase is made, changes or returns are not accepted",
    dinersAdvisement:
      "*Payment available with Diners Club card, does not apply to Titanium and Discover cards"
  },
  selectDate: {
    when: "When do you want to watch your movie?",
    upcomingReleases: "Upcoming releases",
    emptyButton: "See billboard"
  },
  changeStore: {
    changeCinema: "Cambiar cine",
    title: "Are you sure you want to delete your cart?",
    description:
      "You have a cart saved in a cinema, by changing location you will lose your saved cart",
    keepCart: "Keep at",
    changeLocation: "Change at"
  },
  membership: {
    title: "Multiclub",
    metaTitle: "Loyalty",
    memberSince: "Anniversary date: ",
    CurrentLevel: {
      missing: {
        left: "You are missing",
        visit: "visit",
        next: "to reach next level"
      },
      benefits: "Enjoy the benefits of being one of our premium customers",
      historyText: "Transactions history",
      visits: "Visits",
      soon: "Will be available soon"
    },
    LevelCard: {
      visits: "Visits per year",
      current: "Current",
      titleBenefits: "Available benefits",
      instructions: "Enjoy the benefits of your current level",
      bronze: "Bronze",
      silver: "Silver",
      gold: "Gold"
    },
    MembershipLevel: {
      title: "Membership",
      noPoints: "Earn points with your visits",
      visitsText: "Points",
      otherLevels: "Other levels"
    },
    terms: "Multiclub Terms and Conditions",
    ProfileNoMembership: {
      title: "Discover the benefits of being a Multiclub affiliated",
      instructions:
        "Discounts, accumulation and exchange of Multipuntos, promotions and exclusive events",
      join: "Join now"
    },
    SignInModal: {
      needSignUp: "Not a Multiclub member yet?",
      signUp: "Create your membership",
      title: "Enter your password",
      instructions:
        "Sign in with the email and password you use for your Multiclub membership",
      link: "Forgot my password",
      button: "Multiclub sign in",
      mutationError: "Failed to link your account, please try again"
    },
    SignUpModal: {
      needSignIn: "Are you a Multiclub member?",
      signIn: "Sign in to your account",
      title: "enjoy your Multiclub benefits",
      instructions:
        "Fill your personal details and become part of the Multiclub family",
      button: "Continue",
      mutationError: "Registration failed, please try again",
      termsPre: "I accept the",
      terms: "terms and conditions",
      termsPost: "of the Multiclub plan",
      benefits: "Join as a Multiclub member"
    },
    ProfileMembership: {
      level: "Level",
      visits: "Visits",
      check: "Check membership",
      title: "Membership"
    },
    MembershipNotification: {
      title: "Enjoy the Multiclub benefits",
      join: "Join"
    },
    ChooseMembership: {
      title: "Discover the benefits of being a Multiclub affiliated",
      instructions:
        "Discounts, accumulation and exchange of Multipuntos, promotions and exclusive events",
      signIn: "I'm already a Multiclub member",
      join: "Join as a new member"
    },
    Confirmation: {
      title: {
        loading: "Signing in...",
        success: "Sign in successfully",
        error: "Something went wrong trying to sign in"
      }
    },
    RecoverPasswordSection: {
      title: "Recover your password",
      description: "Enter your email address to recover your password",
      successTitle: "Email sent",
      successMessage: "An email was sent to the provided email address"
    },
    Verification: {
      title: "We have sent an email to",
      instructions: "Verify your email and sign in",
      noResponse: "Didn't receive an email?",
      resend: "Send again"
    },
    ResetPassword: {
      title: "Set your new password",
      repeat: "Repeat your new password",
      create: "Set new password",
      instructions:
        "To sign in back to your account, enter a new strong password"
    },
    error: {
      title: "We have a problem with the user, contact support!",
      button: "Go Home"
    }
  },
  support: {
    formTitle: "Solve your doubts and queries by calling ",
    formTitle2: " or leave us your comment in the following form:",
    names: "Names",
    lastNames: "LastNames",
    document: "Identity document",
    information: "The document can be an ID, passport or RUC",
    selectStore: "Select a cinema store",
    store: "Cinema store",
    movie: "Movie or show",
    date: "Date of visit",
    claimType: "Type of query",
    comments: "Comments",
    comment: "Comment",
    addComment: "Add a comment",
    error: "User unique identifier or email are undefined",
    errorMessage: "An error occurred while trying to send your message",
    successMessage: "Message sent successfully",
    invalidDate: "Invalid date",
    subjectType: "Type of claim",
    subject: "Costumers commentaries"
  },
  timeout: {
    title: "Your reservation time has expired",
    description:
      "Your reservation time has expired, try again to reserve your tickets",
    goToBillboard: "Go to billboard",
    backToMovie: "Back to the movie"
  },
  location: {
    to: "To",
    from: "from you"
  },
  politics: {
    title: "We invite you to review the new policies and accept them.",
    description:
      "In MULTICINES S.A. We process the information you provide us with in order to manage commercial initiatives and the relationship with our customers. You have the right to access, update, revoke, delete and oppose the processing of your data. If you want to exercise your right, send us an email to: privacidad@multicines.com.ec",
    termsPre: "I have read and accept the",
    dataTerms: "Data usage treatment",
    required: "* This option is required",
    termsPost: "I have read and accept the",
    terms:
      "I accept the sending of commercial initiatives based on my tastes, preferences and consumption, about MULTICINES products and services by any means. I may revoke consent at any time.",
    multiclubBenefit:
      "Activate this field to receive exclusive benefits as MultiClub members"
  },
  signUpForm: {
    title: "We want to know a little more about you.",
    terms: "I have read and accept the Treatment of data use.",
    privacy:
      "I accept the sending of commercial initiatives based on my tastes, preferences and consumption, about MULTICINES products and services by any means. I may revoke consent at any time.",
    createAccount: "Create account",
    invites: "Don't want to fill in your details?",
    continueButton: "Continue as guest",
    multiclubBenefit:
      "Activate this field to receive exclusive benefits as MultiClub members"
  },
  deleteAccount: {
    errorDeleted: "Could not delete account",
    title: "Delete account",
    question: "Are you sure you want to delete your account?",
    description:
      "Once you delete your account you will not be able to recover it again and you will have to create a new account.",
    cancel: "Keep my account",
    delete: "Delete my data",
    cancelTitle: "Let the show continue",
    cancelDescription: "Sharing is happiness",
    deleteTitle: "Your account has been deleted",
    deleteDescription: "We will miss you, we hope to see you again soon."
  },
  repeatOrder: {
    noLastSnackOrder: "There is no snack order to repeat",
    noCoordinates: "No coordinates selected",
    noRebuildCart: "Could not repeat the snack order",
    noValidateShoppingCart: "Could not validate the shopping cart",
    repeatLastSnackOrder: "Do you want to repeat your last snack purchase?",
    repeatDate: "Repeat your order from ",
    repeatBtn: "Yes, repeat last purchase",
    recommendedProduct: "Recommended product"
  },
  wallet: {
    title: "Wallet",
    corporate: "Corporate Tickets",
    inputTitle: "Do you have a corporate ticket?",
    inputPlaceholder: "Enter your 16-digit code here",
    emptyTitle: "Enter your corporate ticket",
    emptyDescription:
      "You haven't added corporate tickets to your wallet yet, try to redeem a code for your ticket",
    appliedTickets: "Corporate tickets applied",
    info: "To redeem your tickets go to Billboard and select a movie",
    stepsTitle: "Enter the 16 digits of your corporate ticket",
    valid: "Valid for movies only",
    noApplicable: "There are no application changes or returns",
    noAccumulate: "MultiClub members, do not accumulate points or visits",
    applicable:
      "Applies to CCI, Condado, Recreo, Scala, Pomasqui, Mall del Rio Cuenca, Batán, Milenium, Mall del Norte and Mall del Río Guayaquil",
    verify: "Check the format, city and validity before applying the ticket",
    haveTicket: "Do you have a corporate ticket?",
    redeem: "Redeem it here",
    notFound: "Corporate ticket not found",
    noRedeem: "Incorrect Code",
    successRedeem: "Ticket successfully entered",
    alreadyRedeemed: "Exchanged or expired ticket",
    successRemove: "Ticket removed successfully",
    errorRemove: "Could not remove the corporate ticket",
    numberRedemptiosExceeded:
      "You have exceeded the number of redemptions allowed for this ticket",
    invalidTicket: "Ticket does not apply for this movie",
    VoucherRedeemed: "Exchanged or expired ticket"
  },
  placeorder: {
    orderNotFounded: "Order not found",
    dontAcceptPaymentProvider:
      "The payment provider does not accept the payment",
    dontAcceptPaymentMethod: "The payment method is not accepted",
    penddingError: "Error al consultar si existe alguna orden pendiente",
    missingDataPayment:
      "No se pudo realizar el pedido debido a la falta de datos"
  },
  placeToPay: {
    errorValidatePayment: "Error validating payment",
    orderInfoNotFounded: "Order information not found",
    placeToPayScriptNotLoaded: "The place to pay script has not been loaded",
    noPaymentUrl: "No payment URL could be obtained",
    nowProcessingPayment: "Now processing payment...",
    thisProcessCouldTake: "This process could take ...",
    paymentTimeout: "Payment timeout",
    orderAlreadyProcessed: "Your order has already been processed",
    waitAndCheckOrderHistory:
      "Wait a few minutes and check your order history to confirm whether or not your payment was verified",
    checkOrderHistory: "Check your order history"
  },
  talkShop: {
    finishMessage: "Ready! Here is the summary and detail of your purchase",
    submitOrderCode: "Submit your order code",
    orQrCode: "or show the QR code of your order",
    ifLinkNotWork: "If the link does not work, check email"
  },
  otp: {
    sendOtp: "We have sent you an access code to your email ",
    loginAccount: "to be able to log into your account",
    noSendOtp:
      "If you haven't receive the email check on your SPAM tray or you can",
    resendOtp: "Request email again",
    continueGuest: "Continue as guest",
    successSendOtp:
      "The code has been sent to your email, check your inbox and enter the code",
    errorSendOtp: "Could not send the code, try again",
    errorCodeLength: "The code must be 4 digits"
  },
  addresses: {
    title: "Addresses",
    addNew: "Add new address"
  }
};

export default en;
