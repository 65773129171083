import styled from "styled-components";

import { ErrorStateStyledProps as Props } from "./ErrorState.types";

const ErrorStateStyled = styled.div<Props>`
  justify-content: center;
  align-items: center;
  padding: 1.6rem;
  display: flex;
  flex-direction: column;
  flex: 1;

  .ErrorState {
    &__title {
      color: var(--palette-white);
      font-size: 2.8rem;
      line-height: 3.2rem;
      padding-top: 2rem;
      text-align: center;
    }

    &__message {
      color: var(--palette-white);
      font-size: 1.6rem;
      line-height: 1.8rem;
      padding-top: 0.8rem;
      padding-bottom: 2.4rem;
      text-align: center;
    }

    &__button {
      margin-top: 1.6rem;
      color: var(--palette-black-s15-l8);
      font-weight: 700;
    }
  }
`;

export default ErrorStateStyled;
