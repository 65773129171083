import InfoSVG from "images/info.svg";
import React from "react";
import { useQueryClient } from "react-query";

import Styles from "./ErrorBlock.styles";
import { ErrorBlockProps as Props } from "./ErrorBlock.types";
import Button from "components/global/Button/Button";

const ErrorBlock: React.FC<Props> = props => {
  const { className, queryKey } = props;
  const queryClient = useQueryClient();

  return (
    <Styles className={`ErrorBlock ${className}`}>
      <div className="ErrorBlock__icon">
        <InfoSVG viewBox="0 0 19 20" />
      </div>
      <p className="ErrorBlock__message">
        Ha ocurrido un error al cargar los datos.
      </p>
      <Button
        className="ErrorBlock__button"
        onClick={() => queryClient.refetchQueries(queryKey)}
      >
        Intentar de nuevo
      </Button>
    </Styles>
  );
};

ErrorBlock.defaultProps = {
  className: ""
};

export default ErrorBlock;
