import { Storage } from "@capacitor/storage";
import { useBillingDataStore } from "@multicines/stores";
import { useEffect } from "react";

import CONSTANTS from "config/constants";
import { useAuthStore } from "stores/auth/auth.store";

const { BILLING_DATA_TOKEN } = CONSTANTS.STORAGE;

const useListenBillingData = () => {
  const selectedBillingData = useBillingDataStore(
    state => state.selectedBillingData
  );
  const setSelectedBillingData = useBillingDataStore(
    state => state.setSelectedBillingData
  );
  const isAnonymous = useAuthStore(state => state.isAnonymous);

  /** Get billing data from local storage and set it on context. */
  useEffect(() => {
    (async () => {
      const { value } = await Storage.get({
        key: BILLING_DATA_TOKEN
      });
      const lastSelectedBillingData = value ? JSON.parse(value) : undefined;

      setSelectedBillingData(lastSelectedBillingData);
    })();
  }, [setSelectedBillingData]);

  /**
   * Every time the selected billing data changes,
   * save it on local storage.
   */
  useEffect(() => {
    if (!selectedBillingData || isAnonymous) return;
    Storage.set({
      key: BILLING_DATA_TOKEN,
      value: JSON.stringify(selectedBillingData)
    });
  }, [isAnonymous, selectedBillingData]);
};

export default useListenBillingData;
