import { useFetchShippingAddresses } from "@multicines/services";
import LocationSVG from "images/location.svg";
import React from "react";

import { getMatchingAddresses } from "./AddressList.helpers";
import Styles from "./AddressList.styles";
import { AddressListProps as Props } from "./AddressList.types";
import ChooseAddress from "../ChooseAddress/ChooseAddress";
import CommonPlaceholder from "components/global/CommonPlaceholder/CommonPlaceholder";
import ErrorBlock from "components/global/ErrorBlock/ErrorBlock";
import { useAuthStore } from "stores/auth/auth.store";

const AddressList: React.FC<Props> = props => {
  const { actions, className, onEdit, onSelect, searchResult } = props;
  const { dropdownFixed } = props;
  const isAnonymous = useAuthStore(state => state.isAnonymous);
  const uid = useAuthStore(state => state.uid);
  const {
    data: addressList,
    isLoading,
    isError
  } = useFetchShippingAddresses({ isAnonymous, uid });
  const empty = addressList && addressList.length === 0;

  const placeholdersNode = (
    <>
      <CommonPlaceholder />
      <CommonPlaceholder />
      <CommonPlaceholder />
      <CommonPlaceholder />
      <CommonPlaceholder />
    </>
  );

  const emptyNode = (
    <div className="AddressList__empty">
      <div className="AddressList__empty__icon">
        <LocationSVG
          className="AddressList__student-icon"
          viewBox="0 0 16 22"
        />
      </div>
      <p className="AddressList__empty__title">Guarda tus direcciones</p>
      <p className="AddressList__empty__description">
        Agrega una dirección de entrega para realizar tu primera compra
      </p>
    </div>
  );

  const addressListNode = () => {
    if (!searchResult?.length) {
      return addressList?.map(address => (
        <div key={address.id} className="AddressList__item">
          <ChooseAddress
            shippingAddress={address}
            actions={actions}
            onEdit={onEdit}
            onClick={onSelect}
            dropdownFixed={dropdownFixed}
          />
        </div>
      ));
    }
    return addressList
      ?.filter(address => getMatchingAddresses(address, searchResult))
      .map(address => (
        <div key={address.id} className="AddressList__item">
          <ChooseAddress
            shippingAddress={address}
            actions={actions}
            onClick={onSelect ?? onEdit}
            dropdownFixed={dropdownFixed}
          />
        </div>
      ));
  };

  return (
    <Styles className={`AddressList ${className ?? ""}`}>
      {isLoading ? placeholdersNode : null}
      {empty && !searchResult ? emptyNode : null}
      {isError ? <ErrorBlock queryKey={[uid, "addresses"]} /> : null}
      {!isLoading && !empty ? addressListNode() : null}
    </Styles>
  );
};

AddressList.defaultProps = {};

export default AddressList;
