import ErrorSVG from "images/error.svg";
import React from "react";

import Styles from "./ErrorState.styles";
import { ErrorStateProps as Props } from "./ErrorState.types";
import Button from "../Button/Button";
import useI18n from "hooks/useI18n";

const ErrorState: React.FC<Props> = props => {
  const { className, title, icon, description, buttonTitle, onClick } = props;
  const t = useI18n();

  return (
    <Styles className={`ErrorState ${className}`}>
      {icon ?? <ErrorSVG />}
      <p className="ErrorState__title">{title ?? t.common.errorTitle}</p>
      <p className="ErrorState__message">
        {description ?? t.common.errorMessage}
      </p>
      <Button
        mode="PRIMARY"
        className="ErrorState__button"
        onClick={() => onClick?.()}
      >
        {buttonTitle ?? t.common.retry}
      </Button>
    </Styles>
  );
};

ErrorState.defaultProps = {
  className: ""
};

export default ErrorState;
